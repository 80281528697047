import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {AppBar, Button, Tab, Tabs, TextField} from "@material-ui/core";
import {OnSwitchingCallbackTypeDescriptor} from "react-swipeable-views";
import {TabPanel, useStyles} from "../App";
import {useTheme} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../app/appState";
import {
    checkSecureCode,
    getActiveUsers,
    getQrCodeUrlValue,
    resetUseTokenData, saveEditUserData,
    saveUserAuthData,
    saveUserBaseData,
    saveUserContactData,
    saveUserPaymentData, setEditUserData,
    setEditUserDataObject,
    setImportFile,
    setNewPasswordData,
    setUserData,
    startImportUserData,
} from "../reducers/userReducer";
import PasswordStrengthBar from "react-password-strength-bar";
import DVInfo from "../components/dvinfo";
import AppSnackbar from "../components/snackbar";
import {sendNewsletterMail, setAppData, setNewsletterText, showFailedSnackbar} from "../reducers/appReducer";
import {CopyToClipboard} from "react-copy-to-clipboard";
import AppEditor from "../components/editor";
import JqxGrid, {jqx} from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import BaseData from "../components/user/basedata";
import ContactData from "../components/user/contactdata";
import PaymenmtData from "../components/user/paymentdata";
import AuthData from "../components/user/authdata";
import OtherData from "../components/user/otherdata";
import JqxFileUpload from "jqwidgets-scripts/jqwidgets-react-tsx/jqxfileupload";


function a11yProps(index: any) {
    return {
        id: `sub-tab-${index}`,
        // 'aria-controls': `vertical-tabpanel-${index}`,
        tabIndex: index
    };
}

const userBaseData = ['FirstName', 'LastName', 'Birthday', 'FCBOnline', 'Newsletter', 'PublicPhoto', 'PublicBirthday'];
const userContactData = ['Street', 'HomeNumber', 'CountryCode', 'ZipCode', 'City', 'UseEmail', 'UserPhoneAndMobile'];
const userPaymentData = ['BankName', 'Owner', 'IBAN', 'BIC'];
const authData = ['authCode'];

function BenutzerSeite() {

    const stateData = useSelector((state: AppState) => state);
    const appData = stateData.appData;
    const userData = stateData.userData;
    const user = userData.currentUser;

    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
        if (user.Id === "" || user.Id === null) {
            window.location.href = "#/aktuelles";
        }
    }, []);

    const [value, setValue] = React.useState(0);

    const [value2, setValue2] = React.useState(0);

    const [value3, setValue3] = React.useState(0);

    const [copied, setCopied] = React.useState(false);

    const [newsText, setNewsText] = React.useState("");


    const copyToClipboard = () => {
        setCopied(true);
        // if(props.onValidateAuthCode !== undefined)
        //     props.onValidateAuthCode("");
    };

    const setImportDialog = (value: boolean) => {
        dispatch(setAppData({key: "openImportDialog", value}));
    }

    const validateSecureCode = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= 6) {
            dispatch(setUserData({key: "code", value: String(e.target.value)}));
        }
        if (e.target.value.length === 6) {
            dispatch(checkSecureCode());
        }
    };

    const BaseInitial = {
        FirstName: {
            error: false,
            helperText: ''
        },
        LastName: {
            error: false,
            helperText: ''
        },
        Birthday: {
            error: false,
            helperText: ''
        }
    };

    const [Base, setBaseState] = React.useState(BaseInitial);

    const ContactInitial = {
        Street: {
            error: false,
            helperText: ''
        },
        HomeNumber: {
            error: false,
            helperText: ''
        },
        CountryCode: {
            error: false,
            helperText: ''
        },
        ZipCode: {
            error: false,
            helperText: ''
        },
        City: {
            error: false,
            helperText: ''
        },
        Email: {
            error: false,
            helperText: ''
        }
    };

    const [Contact, setContactState] = React.useState(ContactInitial);

    const PaymentInitial = {
        BankName: {
            error: false,
            helperText: ''
        },
        Owner: {
            error: false,
            helperText: ''
        },
        IBAN: {
            error: false,
            helperText: ''
        },
        BIC: {
            error: false,
            helperText: ''
        }
    };

    const UserAuthInitial = {
        AuthCode: {
            error: false,
            helperText: ''
        }
    };

    const [Payment, setPaymentState] = React.useState(PaymentInitial);

    const [UserAuth, setUserAuthState] = React.useState(UserAuthInitial);

    const PasswortInitial = {
        LoginPassword: {
            error: false,
            helperText: ''
        },
        NewLoginPassword: {
            error: false,
            helperText: ''
        },
        ConfirmedNewLoginPassword: {
            error: false,
            helperText: ''
        }
    };

    const [Password, setPasswordState] = React.useState(PasswortInitial);

    const tokenInitial = {
        useToken: false
    };

    tokenInitial.useToken = user.Login.twoFactoryAuthentication.useToken;

    const [useTokenData, setUseToken] = React.useState(tokenInitial);

    const setTabIndexValueOnState = (index: number) => {
        setValue(index);
        if (user.Login.twoFactoryAuthentication.qrCodeUrl.length === 0) {
            dispatch(getQrCodeUrlValue());
        }
        if (index == 5) {
            dispatch(getActiveUsers());
        }
    };

    const importData = (files: File[]) => {
        dispatch(setImportFile({importFile: files[0]}));
        dispatch(startImportUserData());
        // Dialog nach Import anzeigen und Benutzerliste aktualisieren
    }

    const editUserTabIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue3(newValue);
    };

    const tabIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndexValueOnState(newValue);
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            switch (newValue) {
                default:
                    bg.className = '';
                    break;
            }
    };

    const swipeableViewSwitching = (index: number, type: OnSwitchingCallbackTypeDescriptor) => {
        if (type === "end")
            setTabIndexValueOnState(index);
    }

    const adminTabIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        switch (newValue) {
            case 0:
                dispatch(getActiveUsers());
                break;
        }

        setValue2(newValue);
    };

    const setNewState = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.id;

        if (value !== 5) {

            if (name === "useToken" && event.target.type === "checkbox") {
                setUseToken({useToken: event.target.checked});
            } else {
                if (event.target.type === "checkbox") {
                    dispatch(setUserData({key: name, value: event.target.checked}));
                } else {
                    dispatch(setUserData({key: name, value: String(event.target.value)}));
                }
                if (userBaseData.indexOf(name) > -1) {
                    dispatch(setUserData({key: "UserDataChanged", value: true}));
                } else if (userContactData.indexOf(name) > -1) {
                    dispatch(setUserData({key: "UserContactChanged", value: true}));
                } else if (userPaymentData.indexOf(name) > -1) {
                    dispatch(setUserData({key: "UserPaymentChanged", value: true}));
                }
            }
        } else {

            if (event.target.type === "checkbox") {
                dispatch(setEditUserData({key: name, value: event.target.checked}));
            } else {
                dispatch(setEditUserData({key: name, value: String(event.target.value)}));
            }

            setTimeout(() => {
                if (event.target.type === "checkbox") {
                    dispatch(setEditUserData({key: name, value: event.target.checked}));
                } else {
                    dispatch(setEditUserData({key: name, value: String(event.target.value)}));
                }
            }, 500);

        }

    };

    const baseFieldsValid = () => {
        let isValid = true;
        let newBaseState = {
            FirstName: {
                error: false,
                helperText: ''
            },
            LastName: {
                error: false,
                helperText: ''
            },
            Birthday: {
                error: false,
                helperText: ''
            }
        };
        if (user.FirstName.length == 0) {
            newBaseState.FirstName.error = true;
            newBaseState.FirstName.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.LastName.length == 0) {
            newBaseState.LastName.error = true;
            newBaseState.LastName.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.Birthday.length == 0) {
            newBaseState.Birthday.error = true;
            newBaseState.Birthday.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        setBaseState(newBaseState);
        return isValid;
    };

    const passwortFieldsValid = () => {
        let isValid = true;
        let newPasswortState = {
            LoginPassword: {
                error: false,
                helperText: ''
            },
            NewLoginPassword: {
                error: false,
                helperText: ''
            },
            ConfirmedNewLoginPassword: {
                error: false,
                helperText: ''
            }
        };
        if (user.Login.loginPassword.length == 0) {
            newPasswortState.LoginPassword.error = true;
            newPasswortState.LoginPassword.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.Login.newLoginPassword.length == 0) {
            newPasswortState.NewLoginPassword.error = true;
            newPasswortState.NewLoginPassword.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.Login.newLoginPasswordConfirmed.length == 0) {
            newPasswortState.ConfirmedNewLoginPassword.error = true;
            newPasswortState.ConfirmedNewLoginPassword.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        setPasswordState(newPasswortState);
        return isValid;
    };

    const contactFieldsValid = () => {
        let isValid = true;
        let newContactState = {
            Street: {
                error: false,
                helperText: ''
            },
            HomeNumber: {
                error: false,
                helperText: ''
            },
            CountryCode: {
                error: false,
                helperText: ''
            },
            ZipCode: {
                error: false,
                helperText: ''
            },
            City: {
                error: false,
                helperText: ''
            },
            Email: {
                error: false,
                helperText: ''
            }
        };
        if (user.Street.length == 0) {
            newContactState.Street.error = true;
            newContactState.Street.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.HomeNumber.length == 0) {
            newContactState.HomeNumber.error = true;
            newContactState.HomeNumber.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.CountryCode.length == 0) {
            newContactState.CountryCode.error = true;
            newContactState.CountryCode.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.ZipCode.length == 0) {
            newContactState.ZipCode.error = true;
            newContactState.ZipCode.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.City.length == 0) {
            newContactState.City.error = true;
            newContactState.City.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.Email.length == 0) {
            newContactState.Email.error = true;
            newContactState.Email.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }

        setContactState(newContactState);
        return isValid;
    };

    const paymentFieldsValid = () => {
        let isValid = true;
        let newPaymentState = {
            BankName: {
                error: false,
                helperText: ''
            },
            Owner: {
                error: false,
                helperText: ''
            },
            IBAN: {
                error: false,
                helperText: ''
            },
            BIC: {
                error: false,
                helperText: ''
            }
        };
        if (user.BankName.length == 0) {
            newPaymentState.BankName.error = true;
            newPaymentState.BankName.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.Owner.length == 0) {
            newPaymentState.Owner.error = true;
            newPaymentState.Owner.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.IBAN.length == 0) {
            newPaymentState.IBAN.error = true;
            newPaymentState.IBAN.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        if (user.BIC.length == 0) {
            newPaymentState.BIC.error = true;
            newPaymentState.BIC.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }
        setPaymentState(newPaymentState);
        return isValid;
    };

    const twoWayAuthFieldsValid = () => {
        let isValid = true;
        let newUserAuthState = {
            AuthCode: {
                error: false,
                helperText: ''
            }
        };
        if (user.Login.twoFactoryAuthentication.code.length == 0) {
            newUserAuthState.AuthCode.error = true;
            newUserAuthState.AuthCode.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }

        setUserAuthState(newUserAuthState);
        return isValid;
    };

    const setNewsEditorValue = (textValue: string) => {
        setNewsText(textValue);
    };

    const saveUserData = () => {
        switch (value) {
            case 0:
                if (baseFieldsValid())
                    dispatch(saveUserBaseData());
                break;
            case 1:
                if (contactFieldsValid())
                    dispatch(saveUserContactData());
                break;
            case 2:
                if (paymentFieldsValid())
                    dispatch(saveUserPaymentData());
                break;
            case 3: // Zwei Faktor Authentifizierung
                if (!userData.currentUser.Login.twoFactoryAuthentication.useToken) {
                    if (twoWayAuthFieldsValid())
                        dispatch(saveUserAuthData());
                } else if (!useTokenData.useToken) {
                    dispatch(resetUseTokenData());
                }
                break;
            case 4: // Kennwort
                if (passwortFieldsValid()) {
                    if (userData.currentUser.Login.newLoginPassword !== userData.currentUser.Login.newLoginPasswordConfirmed) {
                        dispatch(showFailedSnackbar({message: "Die eingegebenen Kennwörter stimmen nicht überein! Bitte wiederhole deine Eingaben."}))
                    } else {
                        dispatch(setNewPasswordData());
                    }
                }
                break;
            case 5: // Administration
                switch (value2) {
                    case 0: // Benutzer
                        console.log(userData.editUser);
                        dispatch(saveEditUserData());
                         break;
                    case 1: // Newsletter
                        alert(newsText);
                        console.log(newsText);
                        dispatch(setNewsletterText({value: newsText}));
                        dispatch(sendNewsletterMail());
                        break;
                    case 2: // Startseite

                        break;
                    case 3: // Aktuelle Termine

                        break;
                    case 4: // Fußballfahrten

                        break;
                    case 5: // Fasching

                        break;
                    case 6: // Sommergrillen

                        break;
                    case 7: // Weihnachtsfeier

                        break;
                    case 8: // Vorstand

                        break;
                }
                break;
        }
    };

    const userGrid = useRef<JqxGrid>(null);

    const theme = useTheme();

    const sourceUserData: any =
        {
            datafields: [
                {name: 'Id', type: 'string'},
                {name: 'RegisterNumber', type: 'string'},
                {name: 'IsActive', type: 'boolean'},
                {name: 'LastName', type: 'string'},
                {name: 'FirstName', type: 'string'},
                {name: 'Birthday', type: 'date'},
                {name: 'MemberBegin', type: 'date'},
                {name: 'MembershipFee', type: 'string'},
                {name: 'FCBOnline', type: 'boolean'},
                {name: 'Newsletter', type: 'boolean'},
                {name: 'PublicPhoto', type: 'boolean'},
                {name: 'PublicBirthday', type: 'boolean'},
                {name: 'UserPhoneAndMobile', type: 'boolean'},
                {name: 'UseEmail', type: 'boolean'},
                {name: 'FirstMember', type: 'boolean'},
                {name: 'BaseChangeDate', type: 'date'},

                {name: 'CountryCode', type: 'string'},
                {name: 'ZipCode', type: 'string'},
                {name: 'City', type: 'string'},
                {name: 'Street', type: 'string'},
                {name: 'HomeNumber', type: 'string'},
                {name: 'Phone', type: 'string'},
                {name: 'Mobile', type: 'string'},
                {name: 'Email', type: 'string'},
                {name: 'ContactChangeDate', type: 'date'},

                {name: 'Owner', type: 'string'},
                {name: 'IBAN', type: 'string'},
                {name: 'BIC', type: 'string'},
                {name: 'IBANSEC', type: 'string'},
                {name: 'BICSEC', type: 'string'},
                {name: 'BankName', type: 'string'},
                {name: 'PaymentChangeDate', type: 'date'},

                {name: 'DeathDate', type: 'date'},
                {name: 'Age', type: 'number'},
                {name: 'Role', type: 'number'},
            ],
            datatype: 'array',
            localdata: userData.Items
        };


    const getISODate = (dateValue: any) => {
        return dateValue !== null && dateValue !== undefined && dateValue !== "" && dateValue !== "0000-00-00" ? dateValue.toISOString().split('T')[0] : "";
    }

    const setSelectedRowData = (rowData: any) => {
        console.log(rowData);
        let d = Object.assign({}, rowData.bounddata);
        console.log(d);
        d.Birthday = getISODate(d.Birthday);
        d.DeathDate = getISODate(d.DeathDate);
        d.MemberBegin = getISODate(d.MemberBegin);
        d.BaseChangeDate = getISODate(d.BaseChangeDate);
        d.ContactChangeDate = getISODate(d.ContactChangeDate);
        d.PaymentChangeDate = getISODate(d.PaymentChangeDate);
        dispatch(setEditUserDataObject({value: d}));

    };

    const tabClick = (event: any) => {
        event.preventDefault();
        switch (event.target.tagName) {
            case "LI":
                var index = 0;
                let li = event.target.parentElement.childNodes;
                for (var i = 0; i < li.length; i++) {
                    li[i].className = "";
                    if (event.target.textContent == li[i].textContent)
                        index = i;
                }
                var divs = event.target.parentElement.parentElement.childNodes;
                for (var j = 1; j < divs.length; j++) {
                    divs[j].style.display = "none";
                }
                divs[index + 1].style.display = "block";
                event.target.className = "selectedTab";
                break;
            default:
                return false;

        }
    }

    const onFilterColumn = (e: Event) => {
        let gridState = userGrid.current!.getstate();
        let gridRows = userGrid.current!.getrows();
        console.log(gridState);
        console.log(gridRows);

    };

    const onRowSelect = (event: any) => {
        if (event != undefined) {
            let args = event.args;
            let rowData = args.row;
            setSelectedRowData(rowData);
        }
        return false;
    };

    const [constructorHasRun, setConstructorHasRun] = useState(false);
    const [userGridData, setUserGridData] = useState({
        columns: [],
        source: []
    });

    const constructor = () => {
        if (constructorHasRun) return;
        setConstructorHasRun(true);
        let data = {
            columns: [
                {text: 'Nr.', datafield: 'Id', hidden: true},
                {text: 'Anmeldenummer', datafield: 'RegisterNumber', pinned: true, width: 150},
                {text: 'aktiv', datafield: 'IsActive', columntype: 'checkbox', filtertype: 'checkedlist', width: 100},
                {text: 'Nachname', datafield: 'LastName', width: 200},
                {text: 'Vorname', datafield: 'FirstName', width: 200},
                {text: 'Geb.-Datum', datafield: 'Birthday', cellsformat: 'dd.MM.yyyy', width: 200},
                {text: 'Land', datafield: 'CountryCode', width: 150},
                {text: 'PLZ', datafield: 'ZipCode', width: 150},
                {text: 'Ort', datafield: 'City', filtertype: 'checkedlist', width: 150},
                {text: 'Strasse', datafield: 'Street', width: 200},
                {text: 'HausNr.', datafield: 'HomeNumber', width: 100},
                {text: 'Telefon', datafield: 'Phone', width: 200},
                {text: 'Mobil', datafield: 'Mobile', width: 200},
                {text: 'E-Mail', datafield: 'Email', width: 200},
                {text: 'bei FCB Online', datafield: 'FCBOnline',  columntype: 'checkbox', filtertype: 'checkedlist', width: 100},
                {
                    text: 'E-Mail-Nutzung',
                    datafield: 'UseEmail',
                    columntype: 'checkbox',
                    filtertype: 'checkedlist',
                    width: 100
                },
                {
                    text: 'Newsletter',
                    datafield: 'Newsletter',
                    columntype: 'checkbox',
                    filtertype: 'checkedlist',
                    width: 100
                },
                {
                    text: 'Bilder Online',
                    datafield: 'PublicPhoto',
                    columntype: 'checkbox',
                    filtertype: 'checkedlist',
                    width: 100
                },
                {
                    text: 'Geb. Gruß',
                    datafield: 'PublicBirthday',
                    columntype: 'checkbox',
                    filtertype: 'checkedlist',
                    width: 100
                },
                {text: 'Beitritt', datafield: 'MemberBegin', cellsformat: 'dd.MM.yyyy', width: 100},
                {
                    text: 'Tel./Handy Kontakt',
                    datafield: 'UserPhoneAndMobile',
                    columntype: 'checkbox',
                    filtertype: 'checkedlist',
                    width: 100
                },
                {
                    text: 'Gründungsmitglied',
                    datafield: 'FirstMember',
                    columntype: 'checkbox',
                    filtertype: 'checkedlist',
                    width: 100
                },
                {
                    text: '2 Fakt. Auth.',
                    datafield: 'UseToken',
                    columntype: 'checkbox',
                    filtertype: 'checkedlist',
                    width: 100
                },
                {text: 'Kontoinhaber', datafield: 'Owner', hidden: true, width: 100},
                {text: 'IBAN', datafield: 'IBAN', hidden: true, width: 100},
                {text: 'BIC', datafield: 'BIC', hidden: true, width: 100},
                {text: 'IBAN', datafield: 'IBANSEC', hidden: true, width: 100},
                {text: 'BIC', datafield: 'BICSEC', hidden: true, width: 100},
                {text: 'Bank', datafield: 'BankName', hidden: true, width: 100},
                {text: 'Verstorben', datafield: 'DeathDate', cellsformat: 'dd.MM.yyyy', width: 200},
                {text: 'Alter', datafield: 'Age', width: 100},
                {text: 'Beitrag', datafield: 'MembershipFee', width: 100},
                {text: 'Role', datafield: 'Role', width: 100},

                {
                    text: 'BaseChangeDate',
                    datafield: 'BaseChangeDate',
                    hidden: true,
                    cellsformat: 'dd.MM.yyyy',
                    width: 100
                },
                {
                    text: 'PaymentChangeDate',
                    datafield: 'PaymentChangeDate',
                    hidden: true,
                    cellsformat: 'dd.MM.yyyy',
                    width: 100
                },
                {
                    text: 'ContactChangeDate',
                    datafield: 'ContactChangeDate',
                    hidden: true,
                    cellsformat: 'dd.MM.yyyy',
                    width: 100
                },
            ],
            source: new jqx.dataAdapter(sourceUserData)
        };
        // @ts-ignore
        setUserGridData(data);
    };

    constructor();

    return (
        <div className="content">
            <AppBar color="transparent" className="appBarSubMenu">
                <Tabs
                    variant="scrollable"
                    className="menuTabs"
                    value={value}
                    indicatorColor="primary"
                    onChange={tabIndexChange}
                >
                    <Tab tabIndex={0} id="Tab1" label="Stammdaten"/>
                    <Tab tabIndex={1} id="Tab2" label="Kontaktdaten"/>
                    <Tab tabIndex={2} id="Tab3" label="Bankverbindung"/>
                    <Tab tabIndex={3} id="Tab4" label="2-Faktor-Authentifizeirung"/>
                    <Tab tabIndex={4} id="Tab5" label="Kennwort &auml;ndern"/>
                    {(user.Id !== "" && user.Role > 0) && <Tab tabIndex={5} id="Tab6" label="Adminstration"/>}
                </Tabs>
            </AppBar>
            <div>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <aside className={classes.maxSize}>
                        <DVInfo/>
                        <BaseData data={user} setNewState={setNewState}/>
                    </aside>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <aside className={classes.maxSize}>
                        <DVInfo/>
                        <p>Hier kannst du deine Kontaktdaten &auml;ndern.</p>
                        <ContactData data={user} setNewState={setNewState}/>
                    </aside>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <aside className={classes.maxSize}>
                        <DVInfo/>
                        <PaymenmtData data={user} setNewState={setNewState} isAdmin/>
                    </aside>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <aside className={classes.maxSize}>
                        <DVInfo/>
                        {!user.Login.twoFactoryAuthentication.useToken &&
                            <aside>
                                <p>Hier kannst du die Zwei-Faktor Authentifizierung f&uuml;r dein
                                    Benutzerkonto
                                    aktivieren.</p>
                                <p>Um die Zwei-Faktor-Authentifizierung bei der Anmeldung nutzen zu können, musst du
                                    eine
                                    App auf deinem Handy installieren, mit der du den Authentifizierungscode erstellen
                                    kannst.</p>
                                <p>Folgende Anwendungen kannst du wahlweise dabei verwenden:
                                    <ul>
                                        <li><a
                                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                            target="_blank">Google Authenticator (Android)</a></li>
                                        <li style={{marginBottom: 10}}><a
                                            href="https://apps.apple.com/de/app/google-authenticator/id388497605"
                                            target="_blank">Google Authenticator (iOS)</a></li>
                                        <li><a
                                            href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=de&gl=US"
                                            target="_blank">Microsoft Authenticator (Android)</a></li>
                                        <li style={{marginBottom: 10}}><a
                                            href="https://apps.apple.com/de/app/microsoft-authenticator/id983156458"
                                            target="_blank">Microsoft Authenticator (iOS)</a></li>
                                        <li><a
                                            href="https://play.google.com/store/apps/details?id=com.lastpass.authenticator"
                                            target="_blank">LastPass Authenticator (Android)</a></li>
                                        <li><a
                                            href="https://itunes.apple.com/us/app/lastpass-authenticator/id1079110004"
                                            target="_blank">LastPass Authenticator (iOS)</a></li>
                                    </ul></p>
                                <p>Hast du eine der Apps auf deinem Handy installiert hast, scannst du über deine
                                    Handy-Kammera den hier abgebildetes QR-Code und fügst so diese Webseite zu deiner
                                    Authentifizierungs-App hinzu.</p>
                                <div>
                                    <img src={user.Login.twoFactoryAuthentication.qrCodeUrl}
                                         alt="Google Authentication QR Code"/>
                                    <div>
                                        <CopyToClipboard text={user.Login.twoFactoryAuthentication.token}
                                                         onCopy={() => copyToClipboard()}>
                                            <button>Code kopieren</button>
                                        </CopyToClipboard>
                                        {copied ?
                                            <div><span style={{color: 'red'}}>Code kopiert.</span></div> : null}
                                    </div>
                                </div>
                                <p>Den von deiner Authentifizierungs-App erzeugten sechstelligen Code gibst du zur
                                    Bestätigung hier dann ein und schließt die Einrichtung mit <b>Speichern</b> ab.</p>

                                <TextField className="kontakt-feld" id="authCode" label="Best&auml;tigungscode"
                                           type="number"
                                           fullWidth
                                           value={user.Login.twoFactoryAuthentication.code}
                                           aria-valuemax={999999}
                                           onChange={validateSecureCode}
                                           error={UserAuth.AuthCode.error}
                                           helperText={UserAuth.AuthCode.helperText}
                                           required/>
                                <p>Mit jeder neuen Anmeldung wirst du neben deinen Anmeldedaten dann künftig einen Code
                                    von
                                    deiner Authentifizierungs-App erzeugen lassen und zusätzlich eingeben
                                    müssen. Dadurch ist dein Benutzerkonto zusätzlich abgesichert, da nur ein Code
                                    eingegeben werden kann, der mit dem bei der Aktivierung verwendeten Handy erzeugt
                                    wurde.
                                    Ein anderes, als das bei der Aktivierung der Zwei-Faktor-Authentifizierzung
                                    benutztes Handy kann für dieses Konto dann keinen gültigen Code erzeugen, selbst
                                    wenn
                                    es die gleiche Authentifizierungs App ist. </p>
                            </aside>
                        }
                        {user.Login.twoFactoryAuthentication.useToken &&
                            <AuthData data={useTokenData} setNewState={setNewState}/>
                        }
                    </aside>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <aside className={classes.maxSize}>
                        <DVInfo/>
                        <p>Hier kannst du dein Kennwort &auml;ndern. Bitte w&auml;hle dabei ein starkes und nicht zu
                            kurzes
                            Kennwort ein. Die Mindestl&auml;nge des Kennwort betr&auml;gt 6 Zeichen. Unterhalb des
                            Kennwortfeldes wird dir die Qualit&auml;t der Kennwortst&auml;rke unmittelbar angezeigt.
                            Bitte best&auml;tige dein neues Kennwort durch die wiederholte Eingabe deines Kennwortes.
                        </p>
                        <div className="infoBox">
                            <p><b>Bitte beachte folgenden Hinweis</b><br/>
                                Bewahre dein Kennwort sicher auf. Wir speichern die Kennw&ouml;rter verschl&uuml;sselt.
                                Solltest du
                                dein Kennwort vergessen haben oder der Meinung sein, jemand hat dein Kennwort
                                ersp&auml;ht, dann
                                kannst du &uuml;ber die Anmeldeseite jederzeit ein neuen Kennwort anfordern.<br/>
                                Bei deiner Anmeldung k&ouml;nnen wir daher immer nur deine Kennworteingabe
                                verschl&uuml;sseln und mit
                                dem verschl&uuml;sselten im System hinterlegten Wert vergleichen.</p>
                        </div>
                        <TextField className="kontakt-feld"
                                   id="Password"
                                   label="aktuelles Kennwort"
                                   type="password"
                                   fullWidth
                                   value={user.Login.loginPassword}
                                   onChange={setNewState}
                                   error={Password.LoginPassword.error}
                                   helperText={Password.LoginPassword.helperText}
                                   required/>
                        <TextField className="kontakt-feld"
                                   id="newLoginPassword"
                                   label="neues Kennwort"
                                   type="password"
                                   fullWidth
                                   value={user.Login.newLoginPassword}
                                   onChange={setNewState}
                                   error={Password.NewLoginPassword.error}
                                   helperText={Password.NewLoginPassword.helperText}
                                   required/>
                        <PasswordStrengthBar
                            minLength={6}
                            password={user.Login.newLoginPassword}
                            scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']}
                            shortScoreWord="zu kurz"/>
                        <TextField className="kontakt-feld"
                                   id="newLoginPasswordConfirmed"
                                   label="neues Kennwort wiederholen"
                                   type="password"
                                   fullWidth
                                   value={user.Login.newLoginPasswordConfirmed}
                                   onChange={setNewState}
                                   error={Password.ConfirmedNewLoginPassword.error}
                                   helperText={Password.ConfirmedNewLoginPassword.helperText}
                                   required/>
                        <PasswordStrengthBar
                            minLength={6}
                            password={user.Login.newLoginPasswordConfirmed}
                            scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']}
                            shortScoreWord="zu kurz"/>
                    </aside>
                </TabPanel>
                {(user.Id !== "" && user.Role > 0) &&
                    <TabPanel value={value} index={5} dir={theme.direction}>
                        <div>
                            <Tabs
                                variant="scrollable"
                                indicatorColor="secondary"
                                value={value2}
                                onChange={adminTabIndexChange}
                                className={classes.tabs}
                            >
                                {['Benutzer',
                                    'Newsletter',
                                    'Startseite',
                                    'Aktuelle Termine',
                                    'Fußballfahrten',
                                    'Fasching',
                                    'Sommergrillen',
                                    'Weihnachtsfeier',
                                    'Vorstand'].map((value: string, index: number) => {
                                    switch (index) {
                                        case 4: // Fußballfahrten nur für Kartenbeauftragten und Admin anzeigen
                                            // @ts-ignore
                                            if (user.Role === 1 || user.Role === 9) {
                                                return <Tab
                                                    label={value} {...a11yProps({index})} />
                                            }
                                            break;
                                        case 0: // Benutzer
                                            if (user.Role >= 2) { // Kassierer, Schriftführer, Vorstand, Admin
                                                return <Tab
                                                    label={value} {...a11yProps({index})} />
                                            }
                                            break;
                                        case 1: // Newsletter
                                        case 2: // Startseite
                                        case 3: // Aktuelle Termine
                                            if (user.Role >= 3) { //  Schriftführer, Vorstand, Admin
                                                return <Tab
                                                    label={value} {...a11yProps({index})} />
                                            }
                                            break;
                                        default:
                                            if (user.Role >= 9) { // Admin
                                                return <Tab
                                                    label={value} {...a11yProps({index})} />
                                            }
                                            break;
                                    }

                                })
                                }
                            </Tabs>

                            <TabPanel value={value2} index={0}>

                                <div>
                                    <ul className="customTabControl"
                                        onClick={tabClick}
                                    >
                                        <li style={{marginLeft: 30}} className="selectedTab">Stammdaten</li>
                                        <li>Kontaktdaten</li>
                                        <li>Bankverbindung</li>
                                        <li>2-Faktor-Authentifizierung</li>
                                        <li>Sonstiges</li>
                                        <li>Benutzerdaten hochladen</li>
                                    </ul>
                                    <div className="tabContent activeTab">
                                        <h4>Stammdaten</h4>
                                        <BaseData data={userData.editUser} setNewState={setNewState} isAdmin/>
                                    </div>
                                    <div className="tabContent">
                                        <h4>Kontaktdaten</h4>
                                        <ContactData data={userData.editUser} setNewState={setNewState} isAdmin/>
                                    </div>
                                    <div className="tabContent">
                                        <h4>Bankverbindung</h4>
                                        <PaymenmtData data={userData.editUser} setNewState={setNewState} isAdmin/>
                                    </div>
                                    <div className="tabContent">
                                        <h4>2-Faktor-Authentifizierung</h4>
                                        <AuthData data={userData.editUser} setNewState={setNewState} isAdmin/>
                                    </div>
                                    <div className="tabContent">
                                        <h4>Sonstiges</h4>
                                        <OtherData data={userData.editUser} setNewState={setNewState} isAdmin role={user.Role}/>
                                    </div>
                                    <div className="tabContent">
                                        <h4>Benutzerdaten hochladen</h4>
                                        <JqxFileUpload accept="xlsx" browseTemplate="primary" fileInputName="FileData"
                                                       uploadUrl="php/ajax.php?call=ImportUserData"/>
                                    </div>
                                </div>
                                <div>
                                    <JqxGrid
                                        ref={userGrid}
                                        width='100%'
                                        height='500px'
                                        source={userGridData.source}
                                        columns={userGridData.columns}
                                        pageable={true}
                                        sortable={true}
                                        enablebrowserselection={true}
                                        altrows={true}
                                        enabletooltips={true}
                                        editable={false}
                                        selectionmode={'singlerow'}
                                        onRowclick={onRowSelect}
                                        filterable={true}/>
                                </div>

                            </TabPanel>

                            <TabPanel value={value2} index={1}>
                                <AppEditor onChange={setNewsEditorValue}/>
                            </TabPanel>
                            <TabPanel value={value2} index={2}>
                                <AppEditor/>
                            </TabPanel>
                            <TabPanel value={value2} index={3}>
                                <AppEditor/>
                            </TabPanel>
                            <TabPanel value={value2} index={4}>
                                <h2>Fu&szlig;ballfahrten/Kartenformular</h2>
                            </TabPanel>
                            <TabPanel value={value2} index={5}>
                                <h2>Fasching/Anmeldeformular</h2>
                            </TabPanel>
                            <TabPanel value={value2} index={6}>
                                <h2>Sommergrillen/Anmeldeformular</h2>
                            </TabPanel>
                            <TabPanel value={value2} index={7}>
                                <h2>Weihnachtsfeier/Anmeldeformular</h2>
                            </TabPanel>
                            <TabPanel value={value2} index={8}>
                                <h2>Vorstandszusammensetzung</h2>
                            </TabPanel>
                        </div>
                    </TabPanel>
                }
            </div>
            <aside>
                <Button variant="outlined" className="btnSubmit" onClick={saveUserData}>
                    Speichern
                </Button>
            </aside>

            <AppSnackbar message={appData.succeded ? appData.succededText : appData.errorText}
                         showSnackbar={appData.succeded || appData.failed}
                         severity={appData.failed ? "error" : "success"}
            />
        </div>
    )
}

export default withRouter(BenutzerSeite);
