import {call, fork, put, select, takeLatest} from "redux-saga/effects";
import {AppState} from "../app/appState";
import {AxiosResponse} from "axios";
import * as service from "../app/axiosService";
import {ContactFormData} from "../app/dto/appData";
import {
    addContact,
      sendNewsletterMail,
    showFailedSnackbar,
    showSuccededSnackbar
} from "../reducers/appReducer";


function* sendContactData() {
    try {
        const contactFormData: ContactFormData = yield select((state: AppState) => state.appData.contactFormular);
        const response: AxiosResponse = yield call(service.sendContactData(contactFormData));
        let data = JSON.parse(response.data);
        // console.log(data);
        if (data === false || data === null || data === undefined) {
            yield put(showFailedSnackbar({message: "Deine Nachricht konnte nicht gesendet werden. Konkatiere uns bitte per E-Mail: webadmin@fcb-hohenfels.de"}));
        } else if (data === true) {
            yield put(showSuccededSnackbar({message: "Deine Nachricht wurde versand. Wir melden uns umgehend bei dir."}));
        }
    } catch (e) {
        console.log(e);
    }
}


function* watchAddContact() {
    yield takeLatest(addContact.type, sendContactData)
}









function* sendNewsletterData() {
    try {
        const mailBody: string = yield select((state: AppState) => state.appData.NewsletterText);
        const response: AxiosResponse = yield call(service.sendNewsletter(mailBody));
        let data = response.data;
        console.log(data);
        if (data.SendNewsletter) {
            yield put(showSuccededSnackbar({message: "Nachricht wurde versendet."}));
        }
        else{
            yield put(showFailedSnackbar({message: "Nachricht konnte nicht versendet werden."}));
        }
    } catch (e) {
        console.log(e);
        yield put(showFailedSnackbar({message: "Nachricht konnte nicht versendet werden. "}));
    }
}


function* watchSendNewsletter() {
    yield takeLatest(sendNewsletterMail.type, sendNewsletterData)
}

const appSaga = [
    fork(watchSendNewsletter),
    fork(watchAddContact)
];


export default appSaga;
